
import '@/assets/scss/index.scss'
import { commonfunc } from '~/mixin/common'

export default {
  components: {
  },
  mixins: [commonfunc],
  data () {
    return {
      outerVisible: false,
      form: {
        ilusrCustMngNo: ''
      }
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    selBst () {
      this.$AxiosBuilder
        .data(this.form)
        .build()
        .post('/mb/mbb/selBst')
        .then((res) => {
          console.log(res)
          if (this.isNotEmpty(res.data.data)) {
            const data = res.data.data
            console.log(data)
            console.log(data.lmtSe)
            console.log(data.lmtSeNm)
            this.form = data
          }
        })
        .catch((err) => {
          console.log('error')
          console.log(err)
        })
    },
    chkStp () {
      // > 1단계 ~ 4단계
      // 레이어 닫힘
      // > 5단계 ~ 6단계
      // 로그아웃 후 홈(화면ID : UX_02_001)으로 이동
      if (this.form.actStp === '5' || this.form.actStp === '6') {
        const userTy = this.$store.getters['session/getUserTy']
        this.$AxiosBuilder
          .data()
          .build()
          .post('/mb/mbb/logout')
          .then((res) => {
            console.log(res)
            this.$store.commit('session/setMember', {}) // 세션객체 초기화
            let loginPage = '/mb/mbb/SCR_MBB_0097'
            loginPage += userTy === '40' ? 'B' : 'A'
            this.$router.push(loginPage)
          })
      } else {
        console.log('부정이용자 1단계~4단계')
        if (this.$store.getters['session/getNoSessionYn'] === 'Y') {
          this.$router.push('/ds')
        } else if (this.$store.getters['session/getNoSessionYn'] === 'N') {
          this.$emit('goReturnUrl', '')
        }
      }
      this.outerVisible = false
    }
  }
}
