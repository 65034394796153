
import '@/assets/scss/index.scss'
import { commonfunc } from '~/mixin/common'

export default {
  components: {
  },
  mixins: [commonfunc],
  data () {
    return {
      agr: {},
      trm: [],
      trmVer: [],
      selVer: ''
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    selectChange () {
      const param = { agr: this.agr, ver: this.selVer }
      this.$emit('verChange', param)
    }
  }
}
