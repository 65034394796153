
import '@/assets/scss/index.scss'

import TabCmp from '@/components/ms/TabCmp.vue'
import { commonfunc } from '~/mixin/common'
import mbUtils from '~/scriptData/mb/utils/mbUtils'
import ScrMbc011502 from '@/pages/mb/mbc/SCR_MBC_0115_02.vue'
import ScrMbc011701 from '@/pages/mb/mbc/SCR_MBC_0117_01.vue'
import ScrMsg0021 from '~/pages/ms/msg/SCR_MSG_0021.vue'

export default {
  components: {
    TabCmp,
    ScrMbc011502,
    ScrMbc011701,
    ScrMsg0021
  },
  mixins: [commonfunc, mbUtils],
  data () {
    return {
      outerVisible: false,
      srcComp: '',
      tabIdx: '1',
      tabsDataSub: [ // 탭 data
        {
          id: '1',
          title: '배송지 관리',
          name: '1'
        },
        {
          id: '2',
          title: '픽업매장 관리',
          name: '2'
        }
      ],
      list: [],
      mbMngNo: '',
      radio: 0
    }
  },
  watch: {
    outerVisible (data) {
      if (data) {
        this.radio = 0
        this.dvyaList()
      } else {
        this.$refs.mbc011701.srchForm.keyword = ''
        this.$refs.mbc011701.strList = []
        this.$refs.mbc011701.selectedIndex = null
        this.$refs.mbc011701.geoData.flag = null
        if (this.$refs.tab) {
          this.tabIdx = '1'
          this.$refs.tab.changeIndex(this.tabIdx)
        }
      }
    },
    // tabIdx (data) {
    //   console.log(data)
    // }
  },
  methods: {
    popupCloseCallback () {
      this.$refs.mbc011502.outerVisible = false
    },
    strDtl (strCd) {
      this.$refs.msg0021.strCd = strCd
      this.$refs.msg0021.outerVisible = true
    },
    dvyaList () {
      this.$AxiosBuilder
        .data()
        .build()
        .post('/mb/mbc/selDvya')
        .then((res) => {
          console.log(res)
          if (this.isNotEmpty(res.data.data)) {
            this.list = res.data.data
            if (document.getElementsByClassName('box-scroll').length > 0) {
              document.getElementsByClassName('box-scroll')[0].scrollTo({ top: 0, left: 0 }) // 스크롤 초기화
            }
          }
        })
        .catch((err) => {
          console.log('error')
          console.log(err)
        })
    },
    dvyaAdit () {
      if (this.list.length >= 20) {
        this.$alert(this.$t('SM.MAX_SAVE_DELIVERY'))
      } else {
        if (this.list.length === 0) {
          this.$refs.mbc011502.bassDvyaYnDisabled = true
        } else {
          // 2024.01.17. 기본배송지가 있는 상태에서 새 배송지 추가 시 기본배송지 여부 선택 가능하도록
          this.$refs.mbc011502.bassDvyaYnDisabled = false
        }
        this.assignNotNull(this.$refs.mbc011502.form, this.$refs.mbc011502.$options.data().form)
        this.$refs.mbc011502.form.action = 'ins'
        this.$refs.mbc011502.outerVisible = true
        if (this.srcComp !== '') {
          this.$refs.mbc011502.srcComp = this.srcComp
        }
      }
    },
    dvyaUpdt (idx) {
      this.assignNotNull(this.$refs.mbc011502.form, this.list[idx])
      this.$refs.mbc011502.bassDvyaYnDisabled = this.list[idx].bassDvyaYn === 'Y'
      this.$refs.mbc011502.form.action = 'upd'
      this.$refs.mbc011502.outerVisible = true
      if (this.srcComp !== '') {
        this.$refs.mbc011502.srcComp = this.srcComp
      }
    },
    dvyaDel (idx) {
      this.$confirm(this.$t('SM.DELETE_DELIVERY_CONFIRM'), '', {
        cancelButtonText: '아니오',
        confirmButtonText: '예',
        dangerouslyUseHTMLString: true
      }).then(async () => {
        await this.$AxiosBuilder
          .data(this.list[idx])
          .build()
          .post('/mb/mbc/upDvyaUseYn')
          .then((res) => {
            console.log(res)
            if (this.isNotEmpty(res.data.data)) {
              const data = res.data.data
              if (res.data.success) {
                this.dvyaList()
              } else {
                this.$alert(data, '', {
                  confirmButtonText: '확인', /* 확인 */
                  dangerouslyUseHTMLString: true
                })
              }
            }
          })
          .catch((err) => {
            console.log('error')
            console.log(err)
          })
      }).catch(() => {
        // 취소버튼
      })
    },
    bassDvyaUpdt () {
      if (this.isNotEmpty(this.radio)) {
        this.list[this.radio].bassDvyaYn = 'Y'
        this.$AxiosBuilder
          .data(this.list[this.radio])
          .build()
          .post('/mb/mbc/upBassDvya')
          .then((res) => {
            console.log(res)
            if (this.isNotEmpty(res.data.data)) {
              const data = res.data.data
              if (res.data.success) {
                this.$alert('기본 배송지가 변경되었습니다.')
                this.radio = 0
                this.dvyaList()
              } else {
                this.$alert(data, '', {
                  confirmButtonText: '확인', /* 확인 */
                  dangerouslyUseHTMLString: true
                })
              }
            }
          })
          .catch((err) => {
            console.log('error')
            console.log(err)
          })
      } else {
        this.$alert('배송지를 선택해주세요.')
      }
    }
  }
}
