
import '@/assets/scss/index.scss'
import { commonfunc } from '~/mixin/common'
import { cmnMsStr } from '~/mixin/ms/msMixin'

export default {
  components: {
  },
  mixins: [commonfunc, cmnMsStr],
  data () {
    return {
      activeName: '1',
      srchForm: {
        mbMngNo: this.$store.getters['session/getMbMngNo'],
        keyword: '',
        srchYn: 'N',

        geolocationAgrYn: 'N',
        curLttd: 0.0,
        curLitd: 0.0,

        currentPage: 1,
        pageSize: 100
      },
      pkupStr: null,
      strList: [],
      totalCnt: 0,

      selectedIndex: null
    }
  },
  watch: {
    'geoData.flag' (val) {
      this.srchForm.geolocationAgrYn = this.geoData.geolocationAgrYn
      this.srchForm.curLttd = this.geoData.strLttd
      this.srchForm.curLitd = this.geoData.strLitd

      this.selStr('N')
    }
  },
  mounted () {
    this.selBassPkupStr()
    this.selGeolocationAgrYn()
  },
  methods: {
    selBassPkupStr () {
      this.$AxiosBuilder
        .data({ mbMngNo: this.$store.getters['session/getMbMngNo'] })
        .build()
        .post('/ms/msg/selBassPkupStr')
        .then((res) => {
          if (this.isNotEmpty(res.data.data)) {
            this.pkupStr = res.data.data
            this.selectedIndex = null
            if (document.getElementsByClassName('box-scroll').length > 0) {
              document.getElementsByClassName('box-scroll')[1].scrollTo({ top: 0, left: 0 }) // 스크롤 초기화
            }
          }
        })
        .catch((err) => {
          console.log('error')
          console.log(err)
        })
    },

    selGeolocationAgrYn () {
      this.selGeolocation()
    },

    selStr (srchYn) {
      this.srchForm.srchYn = srchYn

      if (srchYn === 'Y' && !this.srchForm.keyword) {
        this.$alert('매장명 또는 지역을 입력해 주세요')
        return
      }

      // 조회
      this.$AxiosBuilder
        .data(this.srchForm)
        .build()
        .post('/ms/msg/selPkupStr')
        .then((res) => {
          if (this.isNotEmpty(res.data.data) && res.data.data.length > 0) {
            this.strList = res.data.data
            this.totalCnt = this.strList[0].totalCnt
          } else {
            this.strList = []
            this.totalCnt = 0
          }
          this.selectedIndex = null
        })
    },
    enterKeyUp () {
      if (event.keyCode === 13) {
        this.validStr()
      }
    },
    async validStr () {
      if (this.srchForm.keyword.length === 0) {
        this.$alert('검색어를 입력해 주세요')
        return
      }
      await this.selStr('Y')
    },
    upBassPkupStrYn () {
      if (this.isNotEmpty(this.selectedIndex)) {
        console.log(this.strList[this.selectedIndex])
        this.$AxiosBuilder
          .data(this.strList[this.selectedIndex])
          .build()
          .post('/mb/mbc/upBassPkupStr')
          .then(async (res) => {
            if (this.isNotEmpty(res.data.data)) {
              await this.selBassPkupStr()
              await this.selGeolocationAgrYn()
            }
          })
          .catch((err) => {
            console.log('error')
            console.log(err)
          })
      } else {
        this.$alert('기본 픽업매장으로 선택할 매장을 선택해주세요')
      }
    },
    // 매장상세조회팝업
    strDtl (strCd) {
      this.$emit('strDtl', strCd)
    }
  }
}
