
import '@/assets/scss/index.scss'

import ScrMbc0113 from '@/pages/ds/dsa/SCR_MBC_0113.vue'
import { commonfunc } from '~/mixin/common'

export default {
  components: {
    ScrMbc0113
  },
  mixins: [commonfunc],
  data () {
    return {
      outerVisible: false
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    closeClick () {
      document.getElementsByClassName('pop-cont-wrap')[0].scrollTo({ top: 0, left: 0 }) // 스크롤 초기화
      this.outerVisible = false
    },
    async selFooterTrmCleVerList () {
      await this.$nextTick()
      const agr = { trmTyMngNo: 'MBTY2023092115007875' }
      this.$refs.mbc0113.agr = agr
      await this.$AxiosBuilder
        .data(agr)
        .build()
        .post('/mb/mba/selFooterTrmCleVerList')
        .then(async (res) => {
          if (this.isNotEmpty(res.data.data)) {
            if (res.data.data.length > 0) {
              this.$refs.mbc0113.trmVer = JSON.parse(JSON.stringify(res.data.data))
              this.$refs.mbc0113.selVer = res.data.data[0].ver
              await this.selFooterTrmCle(agr, res.data.data[0].ver)
            }
          }
        })
        .catch((err) => {
          console.log('error')
          console.log(err)
        })
    },
    async selFooterTrmCle (agr, ver) {
      await this.$AxiosBuilder
        .data({ trmTyMngNo: agr.trmTyMngNo, ver })
        .build()
        .post('/mb/mba/selFooterTrmCle')
        .then((res) => {
          if (this.isNotEmpty(res.data.data)) {
            this.$refs.mbc0113.trm = JSON.parse(JSON.stringify(res.data.data))
          }
        })
        .catch((err) => {
          console.log('error')
          console.log(err)
        })
    },
    verChange (param) {
      console.log(param)
      this.selFooterTrmCle(param.agr, param.ver)
    }
  }
}
