
import '@/assets/scss/index.scss'

// import ScrMbc0113 from '@/pages/ds/dsa/SCR_MBC_0113.vue'
import { commonfunc } from '~/mixin/common'

export default {
  components: {
  //  ScrMbc0113
  },
  mixins: [commonfunc],
  data () {
    return {
      outerVisible: false,
      atchFileUrl: ''
    }
  },
  watch: {
    outerVisible (data) {
      if (!data) {
        document.getElementsByClassName('el-dialog__body')[2].scrollTo({ top: 0, left: 0 }) // 스크롤 초기화
      }
    }
  },
  mounted () {
  },
  methods: {
    sel () {
      try {
        this.$AxiosBuilder
          .data()
          .build()
          .post('/ds/exh/selFooterBuySafeSvc')
          .then((res) => {
            const safeSvcData = res.data.data.selBuySafeSvc
            const last = safeSvcData[safeSvcData.length - 1]
            console.log('----------------데이터', last)
            this.atchFileUrl = this.imgUrlMaker(last.atchFileUrl)
          })
      } catch (e) {
        console.log('첨부파일 목록 조회 중 에러 발생 :>> ', e)
      }
    },
    verChange (param) {
      console.log(param)
      this.selFooterTrmCle(param.agr, param.ver)
    }
  }
}
