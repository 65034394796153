
import '@/assets/scss/index.scss'

import TabCmp from '@/components/mb/TabCmp.vue' // 탭
import ScrMbc0113 from '@/pages/ds/dsa/SCR_MBC_0113.vue'
import { commonfunc } from '~/mixin/common'

export default {
  components: {
    TabCmp,
    ScrMbc0113
  },
  mixins: [commonfunc],
  data () {
    return {
      outerVisible: false,
      activeTab: '1', // 탭 index(활성화)
      tabsData: [ // 탭 data
        {
          id: '1',
          title: '온라인 서비스',
          name: '1'
        },
        {
          id: '2',
          title: '멤버십',
          name: '2'
        }
      ],
      agrTy: [
        { trm: 't1', trmTyMngNo: 'MBTY2023092043342042' },
        { trm: 't2', trmTyMngNo: 'MBTY2023092038362093' }]
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    closeClick () {
      document.getElementsByClassName('pop-cont-wrap')[0].scrollTo({ top: 0, left: 0 }) // 스크롤 초기화
      this.outerVisible = false
    },
    async selFooterTrmCleVerList () {
      await this.$nextTick()
      for (let i = 0; i < this.agrTy.length; i++) {
        const agr = this.agrTy[i]
        this.$refs['mbc0113' + agr.trm].agr = agr
        await this.$AxiosBuilder
          .data(agr)
          .build()
          .post('/mb/mba/selFooterTrmCleVerList')
          .then(async (res) => {
            if (this.isNotEmpty(res.data.data)) {
              if (res.data.data.length > 0) {
                this.$refs['mbc0113' + agr.trm].trmVer = JSON.parse(JSON.stringify(res.data.data))
                this.$refs['mbc0113' + agr.trm].selVer = res.data.data[0].ver
                await this.selFooterTrmCle(agr, res.data.data[0].ver)
              }
            }
          })
          .catch((err) => {
            console.log('error')
            console.log(err)
          })
      }
    },
    async selFooterTrmCle (agr, ver) {
      await this.$AxiosBuilder
        .data({ trmTyMngNo: agr.trmTyMngNo, ver })
        .build()
        .post('/mb/mba/selFooterTrmCle')
        .then((res) => {
          if (this.isNotEmpty(res.data.data)) {
            this.$refs['mbc0113' + agr.trm].trm = JSON.parse(JSON.stringify(res.data.data))
          }
        })
        .catch((err) => {
          console.log('error')
          console.log(err)
        })
    },
    verChange (param) {
      console.log(param)
      this.selFooterTrmCle(param.agr, param.ver)
    }
  }
}
